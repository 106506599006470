<template>
  <div style="margin-bottom: 60px">
    <v-app-bar app elevation="7" color="#343a40">
      <v-app-bar-nav-icon @click="changeDrawer"
        v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"></v-app-bar-nav-icon>

      <!-- Mensaje de Bienvenida -->
      <div class="text-bold banner-text" style="font-weight: 700">
        <p style="color: #fff;font-size:20px" class="py-5 my-5">
          Bienvenido a Inprocal, tu academia digital
        </p>
      </div>
      <v-spacer></v-spacer>

      <!-- Información del Usuario -->
      <!-- Información del Usuario -->
      <div class="mx-2" style="display: flex;">
        <!-- Bloque 1: Nombre y Correo -->
        <div style="display: flex; flex-direction: column; align-items: flex-start; margin-right: 10px;">
          <div style="color: #2D383C; font-weight: 700; font-family: 'Segoe UI';">
            {{ firstName }} {{ firstLastName }}
          </div>
          <div style="color: #545454; margin-top: -5px; font-size: 13px;">
            {{ email }}
          </div>
        </div>

        <!-- Bloque 2: Icono de Despliegue -->
        <div class="profile-menu">
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-avatar size="36" v-on="on">
                <v-icon color="#2D383C">mdi-chevron-down</v-icon>
              </v-avatar>
            </template>
            <v-list>
              <v-list-item @click="mostrarPerfil">
                <v-list-item-icon>
                  <v-icon>mdi-account-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Mi Perfil</v-list-item-title>
              </v-list-item>
              <v-list-item @click="cerrarSesion">
                <v-list-item-icon>
                  <v-icon>mdi-logout</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Cerrar Sesión</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>



        <!-- Bloque 3: Icono de Usuario -->
        <div class="profile-avatar-circle">
          <v-img v-if="photoUrl" :src="photoUrl" alt="Foto de Perfil"></v-img>
          <v-icon v-else class="profile-avatar-icon">mdi-account</v-icon>
        </div>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      attrs: {
        class: "pa-2",
      },
      sheet: false,
      search: null,
      numberItems: 0,
      isBadgeActive: false,
      drawer: false,
      dialogCertificate: false,
      stateCertificate: false,
      isLoading: true,
      links: [
        { nombre: "Mi perfil", name: "perfil", nameRouter: "profile" },
        { nombre: "Cerrar Sesión", name: "login", action: "logout" },
      ],
      items: [],
      certificate: null,
      certificateData: {
        data: {},
      },
      course: [],
      finalPrice: null,
      certificateBought: false,
      showCertificateIcon: false,
      name: localStorage.getItem("name_user"),
      email: localStorage.getItem("email_user"),
    };
  },
  computed: {
    firstName() {
      return this.name.split(' ')[0];
    },
    firstLastName() {
      return this.name.split(' ')[1];
    },
  },
  methods: {
    optionAction(action, link) {
      if (action === "logout") {
        window.localStorage.clear();
        this.$router.push({ name: "login" });
      } else if (link.nameRouter === "profile") {
        this.$router.push({ name: "profile" });
      }
    },
    onSearchCourse() {
      if (!this.search) return;
      this.sheet = !this.sheet;
      this.$router.push({ name: "search", query: { q: this.search } }).catch(() => { });
    },
    changeDrawer() {
      this.$emit("click", !this.drawer);
    },
    mostrarPerfil() {
      this.$router.push({ name: "profile" });
    },
    cerrarSesion() {
      window.localStorage.clear();
      this.$router.push({ name: "login" });
    },
  },
};
</script>



<style lang="scss">
.v-label {
  font-size: 0.8em;
}

.scroll {
  height: 100%;
  max-height: 450px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}

a:hover {
  text-decoration: none;
}

.tooltip-box {
  position: absolute;
  background: #000000;
  color: #ffffff;
  padding: 8px 15px;
  z-index: 10;
  top: 35px;
  left: -94px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 15px;
  box-shadow: 2px 2px 2px 2px #000000;
  opacity: 80%;
}

.tooltip-box:hover {
  opacity: 100%;
  transition: 0.3s ease-in;
}

.tooltip-box::after {
  content: "";
  display: block;
  border-bottom: 7px solid #131b1e;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  position: absolute;
  top: -7px;
  left: calc(50% - 7px);
}

.pulse {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    color: #ddce00be;
  }

  100% {
    color: #000000;
  }
}

.ga:hover {
  .box {
    background-color: #fff;
    transform: translatey(50px);
    height: 50px;
    width: 230px;
    color: rgb(0, 0, 0);
    box-shadow: 2px 2px 2px #131b1e;
  }
}

@media screen and (max-width: 900px) {
  .banner-text {
    display: none;
  }
}
</style>
<style>
.profile-avatar {
  display: flex;

  align-items: center;
}

.profile-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 10px;
  font-family: 'Segoe UI';
}

.profile-name {
  color: #2D383C;
  font-weight: 700;
}

.profile-email {
  color: #545454;
  margin-top: -5px;
  font-size: 13px;
}

.profile-dropdown {
  margin-right: 10px;
}

.profile-avatar-circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-avatar-icon {
  color: #545454;



}
</style>